import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();
    var bannerH = $('.index-banner').height();
    if (scroH > bannerH - 100){
        $('.index-page').addClass('white-header')
    }else{
        $('.index-page').removeClass('white-header')
    }

    // 首页单独进行header处理，向上滚动时header的显示与隐藏，banner图向上滚动到一半时header会出现
    if(scroH < bannerH / 2){
      console.log('向下滚动')
      $('.header').css('display','block')
    }else{
      console.log('向上滚动')
      $('.header').css('display','none')
    }

    var solutionTop = $('.index-solution').offset().top
    var solutionBtm = $('.index-solution').offset().top + $('.index-solution').outerHeight()
    if (scroH < solutionTop){
        $('.index-solution .progress-bar').removeClass('fixed')
    } else if (scroH >= solutionTop && scroH < solutionBtm - viewH){
        $('.index-solution .progress-bar').removeClass('abs').addClass('fixed')
    } else if (scroH >= solutionBtm - viewH){
        $('.index-solution .progress-bar').removeClass('fixed').addClass('abs')
    }

    var barH = (scroH - (solutionTop - viewH)) / $('.index-solution').outerHeight()
    $('.index-solution .progress-bar .line').css('height',  barH*100 +'%')

    var solution1Top = $('.index-solution .solution-item').eq(0).offset().top;
    var solution2Top = $('.index-solution .solution-item').eq(1).offset().top;
    var solution3Top = $('.index-solution .solution-item').eq(2).offset().top;

    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH
    if (window.screen.width > 1024){
        if (scroH > solution1Top - viewH / 3 && scroH < solution1Top - viewH / 3 + 20) {
            if (delta > 0) {
                // console.log('下滚1')
                $('html ,body').animate({
                    scrollTop: $('.index-solution .solution-item').eq(0).offset().top
                }, 300);
            }
            if (delta < 0) {
                return false;
            }
        } else if (scroH > solution2Top - viewH / 3 && scroH < solution2Top - viewH / 3 + 20) {
            if (delta > 0) {
                // console.log('下滚2')
                $('html ,body').animate({
                    scrollTop: $('.index-solution .solution-item').eq(1).offset().top
                }, 300);
            }
            if (delta < 0) {
                return false;
            }
        } else if (scroH > solution3Top - viewH / 3 && scroH < solution3Top - viewH / 3 + 20) {
            if (delta > 0) {
                // console.log('下滚3')
                $('html ,body').animate({
                    scrollTop: $('.index-solution .solution-item').eq(2).offset().top
                }, 300);
            }
            if (delta < 0) {
                return false;
            }
        }
    }
    beforeScroH = scroH;
});

//首页banner图部分swiper
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper(".index-banner .swiper-container", {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable: true,
        renderBullet: function (index, className,total) {
            return '<div class="' + className + '"><svg><circle></circle></svg></div>';
        },
    },
    on: {
        init: function (swiper) {
            $('.index-banner .num-pagination .total').html(swiper.pagination.bullets.length)
            $('.index-banner .num-pagination .num').html(swiper.activeIndex)
            $('.index-banner .index-banner-text .text-item').eq(0).addClass('active').siblings().removeClass('active')
        },
        transitionStart: function () {
            $('.index-banner .num-pagination .num').html(this.realIndex + 1)
            $('.index-banner .index-banner-text .text-item').eq(this.realIndex).addClass('active').siblings().removeClass('active')
        },
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
});

//首页新闻部分swiper
var newswiper = new Swiper(".index-news .news-swiper .swiper", {
    slidesPerView: 1.5,
    navigation: {
        nextEl: ".index-news .swiper-button-next",
        prevEl: ".index-news .swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 2.5
        },
        768: {
            slidesPerView: 3.5
        },
        1024: {
            slidesPerView: 3.5
        },
        1280: {
            slidesPerView: 4.5
        },
    },
});
